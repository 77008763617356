import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "HackerRank – Sock Merchant",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:53:20.000Z",
  "url": "/2018/02/hackerrank-sock-merchant/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["Exercises"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Quick and dirty, running on a loop, counting, gathering and calculating the socks 😀`}</p>
    <h6>{`Exercise Description {#exercisedescription}`}</h6>
    <blockquote>
      <p parentName="blockquote">{`John`}{`’`}{`s clothing store has a pile of n loose socks where each sock i is labeled with an integer, C(i), denoting its color. He wants to sell as many socks as possible, but his customers will only buy them in matching pairs. Two socks, i and j, are a single matching pair if C(i)=C(j).`}</p>
      <p parentName="blockquote">{`Given n and the color of each sock, how many pairs of socks can John sell?`}</p>
    </blockquote>
    <h6>{`Solution {#solution}`}</h6>
    <pre><code parentName="pre" {...{}}>{`function getSocksPairs(socks) {  
    var counters = {};
    var pairs = 0;

    for (var i=0 ; i<socks.length ; i++) {
        var cur = socks[i];

        if (cur in counters) {
            counters[cur]++;
        } else {
            counters[cur] = 1;
        }
    }

    for (var counterKey in counters) {
        pairs += Math.floor(counters[counterKey]/2);
    }
    return pairs;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      